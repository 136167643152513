import React, { useState, useEffect } from "react";
import Layout from "../components/layouts/layout";
import LocationCards from "../templates/OurLocation";
import Infoview from "../templates/InfoView";

import mapImage1 from "../assets/Images/mobileView/Our_Locations/OurLocation.png";
import mChennai from "../assets/Images/mobileView/Our_Locations/Chennai.png";
import mBangalore from "../assets/Images/mobileView/Our_Locations/bangalore.png";
import mCoimbatore from "../assets/Images/mobileView/Our_Locations/Coimbatore.png";
import mHyderabad from "../assets/Images/mobileView/Our_Locations/Hyderabad.png";
import Manchester from "../assets/Images/mobileView/Our_Locations/Manchester.png";
import mMumbai from "../assets/Images/mobileView/Our_Locations/Mumbai.png";
import mapImage from "../assets/Images/Homepage/Locations/location.png";
import Banglore from "../assets/Images/Homepage/Locations/Banglore.png";
import Chennai from "../assets/Images/Homepage/Locations/Chennai.png";
import Coimbatore from "../assets/Images/Homepage/Locations/Coimbatore.png";
import Hyderabad from "../assets/Images/Homepage/Locations/Hyderabad.png";
import Mumbai from "../assets/Images/Homepage/Locations/Mumbai.png";
import UK from "../assets/Images/Homepage/Locations/UK.png";
import { BASE_SITE_URL } from "../shared/constants";
//Image List

//Box
import Box from "../templates/Box";

//Two column contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import ogimage from "../assets/Images/ogImages/og-digiryte.png";
import BannerBgImage from "../assets/Images/machine-learning-banner-image.png";
import CardBanner1 from "../assets/Images/newAbout/card1.png";
import CardBanner2 from "../assets/Images/newAbout/card2.png";
import CardBanner3 from "../assets/Images/newAbout/card3.png";

import FormSection from "../components/FormSection";
import RevealAnimation from "../components/RevealAnimation";
import ImageBanner from "../templates/ImageBanner";
import AboutScrollAnimation from "../components/AboutScrollAnimation";
import ReadMoreCard from "../components/MiniCard/abouCard";

const NewAboutUs = () => {
  const InfoviewData = {
    InfoviewTitle: (
      <div className="ttc">
        {" "}
        <span className="red-line3letter red-3letter-mbl">Our</span> Locations
      </div>
    ),
    InfoviewContent: (
      <>
        All our senior management team, tech consultants, client directors and
        account managers are all based in the UK. Our development team, which
        consists of the top 5% of the premium professionals make up our offshore
        talent. We ensure that all data for any project is stored within the UK
        borders only and access to development team is only on an on need basis.
      </>
    ),
  };
  /**
   * Infoview - Ask our clients
   */

  const BoxData = {
    boxBtn1Width: "230px",
    boxBtn1Height: "46px",
    boxBtn1variant: "contained",
    boxBtn1color: "primary",
    boxBtn1label: "Career",
    tip: "Career",
    tooltip: "no",
    Clm2SubTitle: "careers@digiryte.com",
    boxBtn1Title: "Join our team",
    boxBtn1SubTitle: (
      <>
        If you're a designer or a developer <br /> who loves to challenge
        conventions,
        <br /> make the ryte move!
      </>
    ),
    boxBtn2Width: "230px",
    boxBtn2Height: "46px",
    boxBtn2variant: "contained",
    boxBtn2color: "primary",
    boxBtn2label: "Call us right now",
    boxBtn2Title: "Let’s talk",
    tip1: "Call us right now",
    boxBtn2SubTitle: (
      <>
        Have you got an exciting project that <br /> you'd like to discuss?{" "}
      </>
    ),
    route1: "/contact-us",
    route2: "/contact-us",
  };

  const LocationCardData1 = {
    mapImage: mapImage1,
    locationImages: [
      {
        image: Manchester,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: mBangalore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: mHyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: mMumbai,
        imgTitle: "Mumbai",
        route: "/locations/mumbai",
      },
      {
        image: mChennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: mCoimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  const LocationCardData = {
    mapImage: mapImage,
    locationImages: [
      {
        image: UK,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: Banglore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: Hyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: Mumbai,
        imgTitle: "Pune",
        route: "/locations/mumbai",
      },
      {
        image: Chennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: Coimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };

  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: <div>About Us</div>,
  };

  const InfoviewDataAbout = {
    InfoviewTitle: (
      <div className="whowoworkexplore ttc">
        <span className="red-line3letter">Abo</span>ut Digiryte
      </div>
    ),
    tag: "h3",
    width: "95px",
    InfoviewContent: (
      <div className="sub-heading">
        We have worked with industry leaders in technology for over a decade,
        helping them grow their teams quickly and overcome their business
        obstacles.
      </div>
    ),
  };

  const aboutDigiryte = [
    {
      image: CardBanner1,
      title: "Our Pod Model",
      link: "",
      details:
        "Find out about our strategy, which includes managed operations, cultural alignment, and carefully calibrated recruitment.",
      url: "/our-pod-model/",
    },
    {
      image: CardBanner2,
      title: "India",
      link: "",
      details:
        "Explore the tech cluster with the fastest growth in the globe. This place we call home is the Silicon Valley of Asia.",
      url: "/india/",
    },
    {
      image: CardBanner3,
      title: "Insights",
      link: "",
      details:
        "Immerse in our learning culture. Explore expert digital strategies to boost business growth. Access articles and research on business and tech trends. Enjoy the deep dive!",
      url: "/insights/",
    },
  ];

  return (
    <Layout
      seo={{
        title: `About Us | Digiryte`,
        ogtitle: "About Us | Digiryte",
        description:
          "Discover how Digiryte redefines offshore development by pairing elite Indian talent with custom team building, diligent vetting, full ownership, and experienced UK-based guidance.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}`,
        keywords:
          "offshore development, elite Indian talent, custom team building, diligent vetting, UK-based guidance, Digiryte",
      }}
    >
      {/* <HeroSection /> */}
      <ImageBanner imageBannerData={ImageBannerData} />
      <Infoview InfoviewData={InfoviewDataAbout} />
      <div className="mt-70">
        <AboutScrollAnimation />
      </div>

      {/* create card */}
      <div className=" mt-70 common-bg-light">
        <div className="body-container">
          <div className="title-header">
            <span className="red-line3letter">Rea</span>d more about Digiryte
          </div>
          <div className="about-card-section">
            {aboutDigiryte.map((about, index) => {
              return <ReadMoreCard key={index} item={about} />;
            })}
          </div>
        </div>
      </div>

      <div className="mt-70 ">
        <FormSection />
      </div>

      <div className=" mt-70 common-bg-light">
        <RevealAnimation animation="zoom-in" className="">
          <Infoview InfoviewData={InfoviewData} />
        </RevealAnimation>
        <div className="hide-in-mobile">
          <LocationCards
            mapImage={mapImage}
            label="View Location"
            LocationCardData={LocationCardData}
          />
        </div>
        <div className="hide-in-web">
          <LocationCards
            mapImage={mapImage1}
            label="View Location"
            LocationCardData={LocationCardData1}
          />
        </div>
      </div>

      <div className="">
        <Box BoxData={BoxData} />
      </div>
      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default NewAboutUs;
