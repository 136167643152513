import React, { useRef, useEffect } from "react";
import "./styles.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import ProfileCard from "./profileCard";
import Image1 from "../../assets/Images/newAbout/Image1.png";
import Image2 from "../../assets/Images/newAbout/image2.png";
import Image3 from "../../assets/Images/newAbout/Image3.png";
import AutoSlideImage from "../AutoSlideImage";
import Startups_1 from "../../assets/Images/newAbout/startups_1.png";
import Startups_2 from "../../assets/Images/newAbout/startups_2.png";
import Startups_3 from "../../assets/Images/newAbout/startups_3.png";
import Startups_4 from "../../assets/Images/newAbout/startups_4.png";

import BestAppDevelopers from "../../assets/Images/newAbout/awards/BestAppDevelopers.png";
import ClutchChampion from "../../assets/Images/newAbout/awards/ClutchChampion.png";
import ClutchGlobal from "../../assets/Images/newAbout/awards/ClutchGlobal.png";
import TopMobileAppDevelopers from "../../assets/Images/newAbout/awards/TopMobileAppDevelopers.png";
import TopSoftwareDevelopmentCompany from "../../assets/Images/newAbout/awards/TopSoftwareDevelopmentCompany.png";

import AwardListMob from "../../assets/Images/newAbout/awards/award-list.png";
import GraphImg from "../../assets/Images/newAbout/graph.svg";
import ReviewImg from "../../assets/Images/newAbout/review.png";

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

function ScrollAnimation() {
  const pathRef = useRef(null);
  const mainSvgRef = useRef();

  useEffect(() => {
    const path = pathRef.current;

    // Calculate the initial path length
    const initialPathLength = path.getTotalLength();

    gsap.fromTo(
      path,
      {
        strokeDasharray: initialPathLength,
        strokeDashoffset: initialPathLength,
      },
      { strokeDashoffset: 0, duration: 1, ease: "power1.inOut" }
    );

    gsap.to(".moving-element", {
      ease: "sine.inOut",
      transform: "none",
      display: "none",
      motionPath: {
        path: path,
        align: path,
        alignOrigin: [0.5, 0.5],
      },
      scrollTrigger: {
        trigger: ".animation-container",
        start: "0 100%",
        end: "center",
        scrub: true,
        markers: false,
      },
    });

    gsap.to(mainSvgRef.current, {
      attr: { height: 450, viewBox: "0 0 424 450" },
      scrollTrigger: {
        trigger: ".animation-container",
        start: "top center",
        end: "bottom center",
        scrub: true,
        markers: false,
      },
    });

    gsap.to(path, {
      attr: {
        d:
          "M326.888 4H410C415.523 4 420 8.47715 420 14V110.04C420 115.562 415.523 120.04 410 120.04H14C8.47714 120.04 4 124.517 4 130.04V450",
      },
      scrollTrigger: {
        trigger: ".animation-container",
        start: "top center",
        end: "bottom center",
        scrub: true,
        markers: false,
      },
    });

    const arrayOfLengths = gsap.utils.toArray(".list-item");

    arrayOfLengths.forEach((item, index) => {
      const innerElement = item.querySelector(`.item-${index + 1}`);
      gsap.fromTo(
        item,
        { display: "flex", visibility: "hidden", opacity: 0 },
        {
          display: "flex",
          visibility: "visible",
          opacity: 1,
          duration: 4,
          ease: "back.inOut",
          scrollTrigger: {
            trigger: innerElement,
            start: "top center",
            end: "top center",
            scrub: true,
            markers: false,
          },
        }
      );
    });
  }, []);

  const awardsData = [
    { imgUrl: TopSoftwareDevelopmentCompany, className: "animate_up" },
    { imgUrl: ClutchGlobal, className: "animate_down" },
    { imgUrl: ClutchChampion, className: "animate_up" },
    { imgUrl: BestAppDevelopers, className: "animate_down" },
    { imgUrl: TopMobileAppDevelopers, className: "animate_up" },
  ];
  return (
    <>
      <div className="body-container relative animation-trigger">
        <section className="animation-container">
          <h2 className="heading-text">
            We believe we're <br /> fairly skilled at it
          </h2>
          <svg
            width="424"
            ref={mainSvgRef}
            height="250"
            viewBox="0 0 424 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="animation-path"
              ref={pathRef}
              d="M326.888 4H410C415.523 4 420 8.47715 420 14V110.04C420 115.562 415.523 120.04 410 120.04H14C8.47714 120.04 4 124.517 4 130.04V450"
              stroke="#DB4435"
              strokeWidth="8"
              strokeLinecap="round"
            />
          </svg>

          <div className="moving-element">
            <div className="inner-element"></div>
          </div>
        </section>
        <div className="content-list">
          <div className="list-item list-item-1">
            <div className="pipeline-track-1">
              <div className="inner-element item-1 circle"></div>
              <div className="pipeline-content">
                <ProfileCard
                  cardTitle="From humble beginnings to global partner"
                  cardDetails="Founded in Manchester by Ramesh Raja and Dr. Baskar Raju. With a mission to connect the top 3% tech talent, with companies needing top-quality software solutions."
                  title="Prior to entirely remote becoming popular,"
                  subTitle="we were fully remote."
                  imageUrl={Image1}
                />
              </div>
            </div>
          </div>
          <div className="list-item list-item-2">
            <div className="pipeline-track-1">
              <div className="inner-element item-2 circle"></div>
              <div className="pipeline-content">
                <ProfileCard cardTitle="Ever since then..." />
              </div>
            </div>
          </div>

          <div className="list-item list-item-3">
            <div className="pipeline-track-1">
              <div className="inner-element item-3 circle"></div>
              <div className="pipeline-content">
                <ProfileCard
                  cardTitle={
                    <>
                      Over <span>180+</span> projects have been completed for
                      over <span>60</span> distinct companies by us
                    </>
                  }
                  imageUrl={Image2}
                />
              </div>
            </div>
          </div>
          <div className="list-item list-item-4">
            <div className="pipeline-track-1">
              <div className="inner-element item-4 circle"></div>
              <div className="pipeline-content">
                <ProfileCard
                  cardTitle={
                    <>
                      Our{" "}
                      <span>
                        QA engineers, designers, project managers, software
                        developers and other agile tech team members
                      </span>{" "}
                      collaborate on multiple fascinating projects each year
                    </>
                  }
                  imageUrl={Image3}
                />
              </div>
            </div>
          </div>
          <div className="list-item list-item-5">
            <div className="pipeline-track-1">
              <div className="inner-element item-5 circle"></div>
              <div className="pipeline-content">
                <ProfileCard
                  cardTitle={
                    <>
                      ...in <span>startups to Fortune 500 companies,</span> such
                      as ARUP, Channel 4, CERN, and many, many more...
                    </>
                  }
                />
                <div
                  className="mt-30 auto-slide-wrap"
                  // style={{ maxWidth: 800 }}
                >
                  <AutoSlideImage
                    imageUrl={Startups_1}
                    direction="left-to-right"
                  />
                  <AutoSlideImage
                    imageUrl={Startups_2}
                    direction="right-to-left"
                  />
                  <AutoSlideImage
                    imageUrl={Startups_3}
                    direction="left-to-right"
                  />
                  <AutoSlideImage
                    imageUrl={Startups_4}
                    direction="right-to-left"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="list-item list-item-6">
            <div className="pipeline-track-1">
              <div className="inner-element item-6 circle"></div>
              <div className="pipeline-content">
                <ProfileCard
                  cardTitle="We’ve received numerous honours..."
                  cardDetails="Driven by an endless passion for technology, Digiryte is making progress in shaping the app economy. The awards that we have received are a testament to this."
                />
                <div className="awards-animation-wrap">
                  {awardsData.map((award, idx) => (
                    <div
                      className={`awards-animation-container ${award.className}`}
                      key={idx}
                    >
                      <img src={award.imgUrl} alt="" />
                    </div>
                  ))}
                </div>
                <div
                  className="mt-30 auto-slide-wrap award-slide-mob"
                  // style={{ maxWidth: 800 }}
                >
                  <AutoSlideImage
                    imageUrl={AwardListMob}
                    direction="left-to-right"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="list-item list-item-7">
            <div className="pipeline-track-1">
              <div className="inner-element item-7 circle"></div>
              <div className="pipeline-content">
                <ProfileCard
                  cardTitle={
                    <>
                      With an average client engagement of more than{" "}
                      <span>8 years</span> and an industry-leading customer
                      satisfaction score of <span>95%,</span> all of this has
                      contributed to our success.
                    </>
                  }
                />
                <div className="graph-wrap">
                  <img src={GraphImg} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="list-item list-item-8">
            <div className="pipeline-track-1">
              <div className="inner-element item-8 circle"></div>
              <div className="pipeline-content">
                <ProfileCard
                  cardTitle="After all, our faith in talent is what got us started and keeps us going."
                  cardDetails="Every day, more than hundreds of people contribute to our clients’ success. We are most proud of what people say about working here."
                />
                <div className="graph-wrap" style={{ justifyContent: "left" }}>
                  <img src={ReviewImg} alt="" style={{ maxWidth: "100%" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScrollAnimation;
