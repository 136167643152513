import React from "react";
import "./MiniCard.scss";
import Button from "./../../components/Button";
import RightArrow from "../../assets/Icons/go.svg";

function ReadMoreCard({ item }) {
  return (
    <div className="about-card">
      <div>
        <div className="image-about">
          <img src={item.image} loading="lazy" alt={item.title} />
        </div>
        <div className="card-us-section">
          <h2>{item.title}</h2>
          <p>{item.details}</p>
        </div>
      </div>
      <div className="about-button">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={RightArrow}
              alt="Read More"
            />
          }
          customClassName="info-view-resize-btn bg-white"
          color="primary"
          variant="outlined"
          routepath={item.url}
          label="Read More"
          tip="Read More"
          tooltip="no"
        />
      </div>
    </div>
  );
}

export default ReadMoreCard;
