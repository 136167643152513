import React from "react";

function ProfileCard({
  imageUrl = "",
  title = "",
  subTitle = "",
  cardTitle = "",
  cardDetails = "",
}) {
  return (
    <div className="about-the-card">
      {cardTitle && <div class="plain-card-title">{cardTitle}</div>}

      {cardDetails && <div className="mini-view-title">{cardDetails}</div>}
      {title && (
        <div className="card-details-32">
          {title}
          {subTitle && (
            <>
              <br /> <span>{subTitle}</span>
            </>
          )}
        </div>
      )}
      {imageUrl && (
        <div className="mt-10">
          <img
            src={imageUrl}
            loading="lazy"
            style={{ maxWidth: "400px", width: "100%" }}
          />
        </div>
      )}
    </div>
  );
}

export default ProfileCard;
